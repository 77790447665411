@import "../../styles/colors.scss";

.changelog {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.changelog-inner {

  margin: 0;
  padding: 20px 0;
  display: block;
  box-sizing: border-box;
  min-height: 400px;
}

.changelog-hero {

  margin: 0;
  padding: 50px 0;
  padding-bottom: 35px;
  display: block;
  box-sizing: border-box;
  background-color: $background_accent;

}

.changelog-hero-meta {

  margin: 0;
  padding: 80px 0;
  padding-bottom: 50px;
  display: block;
  box-sizing: border-box;

}

.changelog-hero-heading {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 32px;

}

.changelog-hero-slogan {

  margin: 0;
  margin-top: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 16px;
  text-align: center;

}

.timeline-box {
  margin: 0 auto;
  width: 100%;
  margin: 10px;
  padding: 50px 10px;
  display: block;
  box-sizing: border-box;
  margin-top: -85px;
}

.timeline-item{
  color: $logocolor;
}

.timeline-item h4{
  font-weight: bold;
}
.timeline-item p {
  line-height: 1.5;
}


.timeline-item-tags {

  margin-top: 10px;

}

.timeline-item-tags > span {

  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 12px;
  overflow: hidden;
  background-color: $black;
  color: white;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;

}

@media(max-width: 800px) {

  .changelog-hero-slogan {

    margin: 0 auto;
    margin-top: 15px;
    display: table;
    max-width: 50%;
    line-height: 1.5;

  }

}