@import "../../styles/colors.scss";

.about {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.about-inner {

  margin: 0;
  padding: 20px 0;
  display: block;
  box-sizing: border-box;
  min-height: 400px;

}

.about-hero {

  margin: 0;
  padding: 50px 0;
  padding-bottom: 35px;
  display: block;
  box-sizing: border-box;
  background-color: $background_accent;

}

.about-hero-meta {

  margin: 0;
  padding: 80px 0;
  padding-bottom: 50px;
  display: block;
  box-sizing: border-box;

}

.about-hero-heading {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 32px;

}

.about-hero-slogan {

  margin: 0;
  margin-top: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 16px;
  text-align: center;

}

.about-content-section {

  margin: 0;
  padding: 30px 0;
  display: block;
  box-sizing: border-box;

}

.about-content-heading {

  margin: 10px 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 34px;
  text-align: center;

}

.about-content-body {

  margin: 0 auto;
  padding: 30px 0;
  display: block;
  box-sizing: border-box;
  width: 98%;

}

.about-content-body p {

  line-height: 1.8;
  margin: 10px 0;

}

.about-people {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.about-person {

  margin: 0;
  padding: 30px 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;

}

.about-person-inner {

  margin: 0;
  padding: 0 10px;
  display: block;
  box-sizing: border-box;

}

.about-person-meta {

  margin: 0;
  padding: 0;
  padding-top: 10px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 60%;

}

.about-person-image {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 40%;

}

.about-person-image img {

  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  height: 128px;
  border-radius: 128px;
  overflow: hidden;

}

.about-person-name {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 21px;

}

.about-person-title {

  margin: 0;
  margin-top: 5px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 13px;

}

.about-person-desc {

  margin: 0;
  margin-top: 30px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  text-align: left;
  line-height: 1.5;

}

.about-person-contact {

  margin: 0;
  margin-top: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 14px;

}

.about-person-actions {

  margin: 0;
  margin-top: 10px;
  display: block;
  padding: 0;
  box-sizing: border-box;

}

.about-person-action {

  margin: 0;
  margin-right: 15px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  text-decoration: none;

}

.about-person-action span {

  color: $black;
  font-size: 18px;

}

.about-person-action:hover {

  opacity: 0.9;

}

.about-developer-profiles {

  margin: 25px 0;

}

.about-developer-profile {

  margin: 0;
  margin-right: 10px;
  padding: 10px 15px;
  background-color: black;
  color: white;
  text-decoration: none;
  color: 21px;
  border-radius: 6px;
  overflow: hidden;

}

.about-developer-profile > span {

  color: white;
  margin-right: 10px;
  font-size: 21px;

}

.about-developer-profile-google {
  
  background-color: #3DDC84;

}

@media(max-width: 800px) {

  .about-hero-slogan {

    margin: 0 auto;
    margin-top: 15px;
    display: table;
    max-width: 50%;
    line-height: 1.5;

  }

  .about-people {

    margin: 0 30px;

  }

  .about-person {

    width: 100%;

  }

  .about-person-image {

    width: 50%;

  }

  .about-person-meta {

    width: 50%;

  }

  .about-content-body {

    padding: 25px 45px;

  }

  .about-developer-profiles {

    margin: 0 auto;
    display: table;

  }

  .about-developer-profile {
    float: none;
    margin: 20px auto;
    display: table;
  }

}