@import "../../styles/colors.scss";

.product-container {
    margin: 0 auto;
    padding: 80px;
    padding-right: 30px;
    width: 100%;
    position: relative;
    display: table;
    box-sizing: border-box;
    border-color: 1px white solid;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 40px;
}

.product-description {
    width: 35%;
    position: relative;
    display: block;
    float: left;
    z-index: 20;
}

.product-type {
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    line-height: 1.5;
}

.product-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.5;
    padding: 15px 0px;
}

.product-text {
    font-size: 18px;
    line-height: 1.5;
    padding-bottom: 15px;
}

.product-url {
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    line-height: 1.5;
    text-decoration: none;
    padding-bottom: 7px;
}

.product-url:hover {
    border-bottom: 1px black solid;
}

.product-link-icon {
    padding: 5px;
}

.product-images {
    width: 60%;
    position: absolute;
    right: 10px;
    bottom: -10px;
}

.product-images .product-image {
    width: 80%;
    margin: 0 auto;
    display: table;
    z-index: 10;
}

.app-buttons-container {
    margin: 0;
    display: block;
    position: relative;
    margin-top: 20px;
}

.app-button {
    position: relative;
    float: left;
    margin-right: 10px;
}

.app-button img {
    height: 45px;
}

@media (max-width: 800px) {
    .product-images {
        position: relative;
        width: 100%;
        // display: none;
        padding-top: 25px !important;
        bottom: -40px;
    }
    .product-images .product-image {
        // margin-top: 100px;
    }
    .app-button {
        // float: right;
    }
    .product-container {
        // padding-bottom: 380px;
        margin-bottom: 0;
    }

    .product-description {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .product-container {
        // padding-bottom: 380px;
    }

    .product-description {
        width: 100%;
    }
}
