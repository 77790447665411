@import "./colors.scss";

.app-wrapper {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  overflow-x: hidden;

}

.wrapper {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.container {
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  max-width: 1200px;
}

.page-content {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  background-color: $background;

}