@import "../../styles/colors.scss";

.contact {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;

}

.contact-inner {

  margin: 0;
  padding: 20px 0;
  display: block;
  box-sizing: border-box;
  min-height: 400px;

}

.contact-hero {

  margin: 0;
  padding: 50px 0;
  padding-bottom: 35px;
  display: block;
  box-sizing: border-box;
  background-color: $background_accent;

}

.contact-hero-meta {

  margin: 0;
  padding: 80px 0;
  padding-bottom: 50px;
  display: block;
  box-sizing: border-box;

}

.contact-hero-heading {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-align: center;
  font-size: 32px;

}

.contact-hero-slogan {

  margin: 0;
  margin-top: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 16px;
  text-align: center;

}

.contact-meta {

  margin: 0 auto;
  padding: 50px 0;
  display: table;
  box-sizing: border-box;
  width: 50%;

}

.contact-preview {

  margin: 0;
  padding: 50px 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;

}

.contact-preview img {

  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  border-radius: 12px;
  width: 80%;

}

.contact-input {

  margin: 0;
  margin-bottom: 15px;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 2px;
  border: 1px solid gray;

  background-clip: border-box;
  border: 1px solid $gray;
  border-radius: .4rem;

  font-size: 21px;

}

.contact-textarea {

  margin: 0;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 0;
  height: 300px;

  background-clip: border-box;
  border: 1px solid $gray;
  border-radius: .4rem;

  font-size: 21px;

}

.contact-dialog {

  margin: 0 auto;
  margin-top: -25px;
  margin-bottom: 80px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 70%;
  background-color: $white;
  // border: 1px solid $gray;
  border-radius: 16px;
  overflow: hidden;

  box-shadow: 0 0 0 0.05rem $light-gray, 0 0 0.1rem $gray;
  border: 0;

  background-clip: border-box;
  border: 1px solid rgba(164,174,198,.2);
  border-radius: .4rem;

}

.contact-dialog-preview {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 60%;

}

.contact-dialog-preview img {

  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 100%;

}

.contact-dialog-meta {

  margin: 0;
  padding: 25px 30px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 40%;

}

.contact-dialog-heading {

  margin: 15px 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 18px;

}

.contact-dialog-heading-first {

  margin-top: 0;

}

.contact-dialog-heading > span {

  color: $black;
  margin-right: 10px;

}

.contact-dialog-text {

  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 19px;

}

@media(max-width: 800px) {

  .contact-dialog {
    margin-bottom: 10px;
    width: 90%;
  }

  .contact-dialog-preview {

    width: 100%;

  }

  .contact-dialog-meta {

    width: 100%;

  }

  .contact-hero-slogan {

    margin: 0 auto;
    margin-top: 15px;
    display: table;
    max-width: 50%;
    line-height: 1.5;

  }

}