@import "../../styles/colors.scss";

.footer {
  margin: 0;
  padding: 15px 0;
  padding-bottom: 60px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.footer-meta {
  margin: 0;
  padding: 25px 15px;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 50%;
}

.footer-actions {
  margin: 0;
  padding: 15px 15px;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 50%;
}

.footer-links {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: right;
}

.footer-link {
  margin: 0;
  margin-left: 15px;
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  text-decoration: none;
}

.footer-link span {
  color: $black;
  font-size: 30px;
}

.footer-link:hover {
  opacity: 0.9;
}

@media (max-width: 800px) {
  .footer-meta {
    float: none;
    width: 100%;
    padding: 0 30px;
  }

  .footer-business-info {
    text-align: center;
  }

  .footer-links {
    float: none;
    width: 100%;
    padding: 0;
  }

  .footer-actions {
    margin: 10px auto;
    float: unset;
    display: table;
    width: unset;
  }
}
