$white: #fefefe;
$black: #414141;
$gray: gray;

$background_accent: #F6F7F9;
$background: #fefefe;
$accent: $black;
$logocolor: #FFAE3B;
$light-gray: whitesmoke;
$aurorareach-logo: #2C497F;
$aurorareach-light-bg: #DFEAF6; 