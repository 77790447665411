@import "../../styles/colors.scss";

.home {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
}

.home-inner {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
}

.home-hero {
  margin: 0;
  padding: 25px 0;
  padding-bottom: 15px;
  display: block;
  box-sizing: border-box;
  background-color: $background_accent;
}

.home-hero-columns {
  margin: 0;
  padding: 80px 50px;
  display: block;
  box-sizing: border-box;
}

.home-hero-preview {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;
}

.home-hero-preview img {
  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  // width: 50%;
  height: 362px;
}

.home-hero-meta {
  margin: 0;
  padding: 0;
  padding-top: 50px;
  display: block;
  box-sizing: border-box;
  float: left;
  width: 50%;
}

.home-hero-meta-heading {
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: $black;
  font-size: 42px;
  line-height: 1.2;
  font-weight: bold;
}

.home-hero-meta-text {
  margin: 0;
  margin-bottom: 15px;
  font-weight: bold;
  padding: 0;
  display: block;
  box-sizing: border-box;
  color: $black;
  font-size: 24px;
  line-height: 1.2;
}

.home-hero-meta-slogan {
  margin: 0;
  margin-bottom: 50px;
  padding: 0;
  display: block;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.6;
}

.home-hero-meta-actions {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
}

.home-hero-meta-actions a {
  // margin: 0;
  // margin-right: 10px;
  // padding: 10px 15px;
  // display: inline-block;
  // box-sizing: border-box;
  // font-size: 16px;
  // text-transform: uppercase;
  // text-decoration: none;
}

.home-company-name {
  margin: 0 auto;
  text-align: center;
  color: $accent;
  font-size: 84px;
  font-weight: bold;
}

.home-company-slogan {
  margin: 55px auto;
  text-align: center;
  color: $accent;
  font-size: 46px;
  font-weight: bold;
}

.home-company-info {
  margin: 0 auto;
  text-align: center;
  color: $accent;
  font-size: 16px;
  font-weight: bold;
}

.home-company-logo {
  margin: 0 auto;
  display: table;
  width: 40%;
}

.products-title {
  color: $black;
  font-size: 40px;
  font-weight: bold;
  padding: 30px 0;
  text-align: center;
}

.products-intro-container {
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 25px 0px;
  position: relative;
  float: left;
}

.products-intro-meta {
  max-width: 80%;
  text-align: center;
  margin: 0 auto;
}

.products-intro-desc {
  padding: 25px 0px;
}

.products-intro-desc p {
  font-size: 20px;
  text-align: center;
}

.products-links {
  width: 20%;
  padding: 25px 0px;
  position: relative;
  float: left;
}

.products-container {
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 25px 0px;
}

.products-button {
  text-decoration: none;
  background-color: $logocolor;
  color: $white;
  padding: 15px;
  border: 1px $white solid;
  border-radius: 100px;
  font-weight: bold;
}

.products-button:hover {
  color: $logocolor;
  background-color: $white;
  border: 2px $logocolor solid;
}
.logotext {
  margin-left: -15px;
  height: 72px;
  margin-bottom: 10px;
}

.subscribe-prefix {
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
}

.subscribe-suffix {
  margin: 0;
  margin-top: 10px;
  padding: 0;
  display: block;
  box-sizing: border-box;
}

.subscribebox {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 42px;
  border: 1px solid #ffae3b;
  border-radius: 6px;
  overflow: hidden;
  input {
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 75%;
    border: 0;
    padding: 0 15px;
    font-size: 23px;
  }
  input::placeholder {
    color: gray;
  }
  button {
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 25%;
    border: 0;
    background-color: #ffae3b;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.experiment-item-block {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  padding-right: 15px;
  padding-top: 15px;
  .experiment-item-block-inner {
    margin: 0;
    padding: 25px;
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    background-color: white;
    .experiment-item-block-image {
      margin: 0;
      margin-bottom: 25px;
      padding: 0;
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 250px;
      // background-color: red;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .experiment-item-block-heading {
      margin: 0;
      padding: 0;
      display: block;
      box-sizing: border-box;
      font-size: 24px;
      font-weight: bold;
      text-decoration: none;
    }
    .experiment-item-block-desc {
      margin: 0;
      margin-top: 15px;
      padding: 0;
      display: block;
      box-sizing: border-box;
      font-size: 13px;
      line-height: 1.4;
      text-decoration: none;
    }
    .experiment-item-block-website {
      margin: 0;
      margin-top: 15px;
      padding: 0;
      display: block;
      box-sizing: border-box;
      font-size: 11px;
      line-height: 1.4;
      text-decoration: none;
    }
    .experiment-item-block-timestamp {
      margin: 0;
      margin-top: 15px;
      padding: 0;
      display: block;
      box-sizing: border-box;
      font-size: 11px;
      line-height: 1.4;
      text-decoration: none;
      font-weight: bold;
    }
  }
  .experiment-item-block-inner:hover {
    opacity: 0.8;
  }
}

.expertiment-block {
  margin: 0;
  padding: 0;
  display: block;
  box-sizing: border-box;
  // background-color: #feea93;
  border-radius: 6px;
  overflow: hidden;
  padding: 50px;
  .expertiment-block-inner {
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    .expertiment-block-column {
      margin: 0;
      padding: 0;
      display: block;
      box-sizing: border-box;
      float: left;
      width: 50%;
      padding: 15px;
    }
    .expertiment-block-contact-question {
      margin: 10px 0;
      padding: 0;
      display: block;
      box-sizing: border-box;
      font-size: 26px;
      font-weight: 100;
      line-height: 1.4;
      a {
        text-decoration: none;
        // color: gray;
        color: black;
        font-weight: bold;
      }
    }
    .expertiment-block-heading {
      margin: 0;
      padding: 0;
      display: block;
      box-sizing: border-box;
      font-size: 32px;
      font-weight: bold;
    }
    .expertiment-block-desc {
      margin: 0;
      margin-top: 15px;
      padding: 0;
      display: block;
      box-sizing: border-box;
      font-size: 17px;
      line-height: 1.5;
    }
  }
}

@media (max-width: 800px) {
  .expertiment-block {
    border-radius: 0;
    .expertiment-block-inner {
      padding-top: 35px;
      padding-bottom: 35px;
      .expertiment-block-column {
        width: 100%;
      }
      .expertiment-block-column-second {
        padding-top: 30px;
        padding-bottom: 50px;
      }
    }
  }

  .home-hero {
    padding-bottom: 50px;
  }

  .home-hero-meta-text {
    margin: 25px auto;
    font-size: 16px;
    display: table;
    width: 100%;
    text-align: center;
  }

  .logotext {
    height: auto !important;
    width: 100%;
    margin: 0 auto !important;
    margin-bottom: 32px;
    display: table;
  }

  .product-logo-div {
    float: none;
    margin: 0 auto;
    display: table;
  }

  .home-hero-meta {
    padding-top: 50px;
  }

  .home-hero-preview {
    width: 100%;
  }

  .home-hero-preview img {
    height: auto;
    width: 80%;
  }

  .home-hero-meta {
    width: 100%;
  }

  .home-hero-columns {
    padding-top: 50px;
    padding-bottom: 0;
  }
  .products-title {
    text-align: center;
    // font-size: 10px;
    // float: left;
    margin-bottom: -25px;
    position: relative;
  }

  .products-intro-desc {
    width: 100%;
  }
  .products-intro-desc p {
    text-align: center;
  }

  .products-intro-meta {
    max-width: 75%;
    text-align: center;
    margin: 0 auto;
    line-height: 1.5;
  }
}
