@import "./colors.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
}

/**

#2C497F
#784084 
#9796F2
#4767B7
#C66B86

**/

h1, h2,  h3, h4, h5, h6 {

  color: $black;

}

p {

  color: #333;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.branding-color {
  color: #07CA40;
}

.clearfix {

  margin: 0;
  padding: 0;
  clear: both;
  box-sizing: border-box;

}

.wrapper {

  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.loading {

  background-color: #414C72;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

}

.loading-text {

  margin: 0 auto;
  margin-top: 20%;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 26px;
  text-transform: lowercase;

}